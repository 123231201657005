import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';
import api from '../services/index';

export const quoteSlice = createSlice({
  name: 'quote',
  initialState: {
    formDetails: {},
    formPanels: [],
    formPanelWidgets: [],
    formConfigs: {},
    quoteDetails: {},
    solarOptions: [],
    selectedSolarOption: {},
    decisions: [],
  },
  reducers: {
    setFormDetails: (state, { payload }) => {
      state.formDetails = payload;
    },
    setFormPanels: (state, { payload }) => {
      state.formPanels = payload;
    },
    setFormPanelWidgets: (state, { payload }) => {
      state.formPanelWidgets = payload;
    },
    setFormConfigs: (state, { payload }) => {
      state.formConfigs = payload;
    },
    setQuoteDetails: (state, { payload }) => {
      state.quoteDetails = payload;
    },
    setSolarOptions: (state, { payload }) => {
      state.solarOptions = payload;
    },
    setSelectedSolarOption: (state, { payload }) => {
      state.selectedSolarOption = payload;
    },
    setDecisions: (state, { payload }) => {
      state.decisions = payload;
    },
  },
});

const getHeaders = api_key => {
  return { headers: { 'x-api-key': api_key } };
};

const getTokenHeader = token => {
  return { headers: { Authorization: `Bearer ${token}` } };
};

export const getFormDetails = payload => async dispatch => {
  try {
    const { form_id, store = false } = payload;
    const { data } = await api.get(`/self-service/forms/${form_id}`);
    if (store) dispatch(setFormDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanels =
  ({ api_key, form_id, store = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/forms/${form_id}/panels`, getHeaders(api_key));
      if (store) dispatch(setFormPanels(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormPanelWidgets =
  ({ api_key, panel_id, store }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/panels/${panel_id}/widgets`, getHeaders(api_key));
      if (store) dispatch(setFormPanelWidgets(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createJob =
  ({ api_key, request }) =>
  async () => {
    try {
      const { data } = await api.post(`/jobs`, request, getHeaders(api_key));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getFormConfigs = payload => async dispatch => {
  try {
    const { form_id, api_key, store } = payload;
    const { data } = await api.get(`/forms/${form_id}/configs`, getHeaders(api_key));
    if (store) dispatch(setFormConfigs(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getJobTypes =
  ({ api_key, params = {} }) =>
  async () => {
    try {
      const { data } = await api.get(`/job_types`, { ...getHeaders(api_key), params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getQuoteDetails = payload => async dispatch => {
  try {
    const { quote_id, token, store } = payload;
    const { data } = await api.get(`/self-service/quotes/${quote_id}`, getTokenHeader(token));
    if (store) dispatch(setQuoteDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPropertySolarDetails = payload => async dispatch => {
  try {
    const { property_id, api_key } = payload;
    const { data } = await api.get(`/properties/${property_id}/solar_options`, getHeaders(api_key));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductComponents = payload => async () => {
  try {
    const { product_id, api_key, params = {} } = payload;
    const { data } = await apiV3.get(`/api/products/${product_id}/components`, { ...getHeaders(api_key), params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateQuote = payload => async dispatch => {
  try {
    const { quote_id, token, request } = payload;
    const { data } = await api.put(`/self-service/quotes/${quote_id}`, request, { ...getTokenHeader(token) });
    dispatch(setQuoteDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDecisions =
  ({ engagement_id, token }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/self-service/engagements/${engagement_id}/decisions`, getTokenHeader(token));
      dispatch(setDecisions(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateQuoteContact =
  ({ contact_id, request, token }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/self-service/contacts/${contact_id}`, request, getTokenHeader(token));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateProgress =
  ({ engagement_id, token, request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(
        `/self-service/engagements/${engagement_id}/progress`,
        request,
        getTokenHeader(token),
      );
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {
  setFormDetails,
  setFormPanels,
  setFormPanelWidgets,
  setFormConfigs,
  setQuoteDetails,
  setSolarOptions,
  setSelectedSolarOption,
  setDecisions,
} = quoteSlice.actions;
export default quoteSlice.reducer;
