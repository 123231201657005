import React, { Fragment, useState } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ModalContext } from '../context/ModalContext';
import Main from '../pages/main/main';
import SolarDesign from '../pages/solar-design';
import ThankYou from '../pages/thank-you';
import Modal from '../popup';
import Toast from '../popup/toast/toast';
import history from './history';

const routes = createBrowserRouter([
  {
    path: 'thank-you',
    element: <ThankYou />,
  },
  {
    path: 'get-quote/:form_id',
    element: <Main />,
  },
  {
    path: 'solar-design/forms/:form_id',
    element: <SolarDesign />,
  },
  {
    path: '*',
    element: <Navigate replace to={'/get-quote/a39c40dd-db9a-11ee-addf-42010a9a0010'} />,
  },
]);

export const Router = () => {
  const [modal, setModal] = useState({});

  return (
    <Fragment>
      <ModalContext.Provider value={{ modal, setModal }}>
        <RouterProvider history={history} router={routes} />
        <Modal />
        <Toast />
      </ModalContext.Provider>
    </Fragment>
  );
};
