export const specialCharRegEx = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

export const SOLAR_PAYMENT_OPTIONS = [
  {
    label: 'Cash',
    value: 'CASH',
  },
  {
    label: 'Financing',
    value: 'FINANCING',
  },
];
