import classNames from 'classnames';
import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';
import { ReactComponent as Batteries } from '../../assets/images/batteries.svg';
import { ReactComponent as Invertor } from '../../assets/images/invertor.svg';
import { ReactComponent as SolarPanel } from '../../assets/images/panel.svg';
import { SOLAR_PAYMENT_OPTIONS } from '../../constant/optionData';
import { ModalContext } from '../../context/ModalContext';
import { calculatePeriodicPaymentAmount, getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import SolarDesignContext from '../../pages/solar-design/SolarDesignContextApi';
import Button from '../common/button/button';
import RadioButton from '../common/radio-button/radio-button';

const Finance = ({ panel }) => {
  const theme = useTheme();
  const { setModal } = useContext(ModalContext);

  const { quoteDetails, selectedSolarOption, decisions = [], ...rest } = useSelector(state => state.quote);
  const { customTheme, api_key, token } = useContext(SolarDesignContext);
  const [paymentOption, setPaymentOption] = useState('CASH');

  const { estimate, product } = selectedSolarOption || {};
  const { annual_production_kwh } = estimate || {};
  const { component_maps, pricing, id: productId } = product || {};

  const { products } = quoteDetails || {};
  const quoteProductIds = products?.map(p => p?.product?.id);
  const isQuoteProduct = quoteProductIds?.includes(productId);

  const productBundles = useMemo(() => {
    if (!component_maps) return { panels: 0, inverters: 0, batteries: 0 };
    return component_maps.reduce(
      (acc, item) => {
        const { name, components } = item;
        const componentQuantitySum =
          components?.filter(c => c.isSelected)?.reduce((acc, item) => acc + item.quantity, 0) || 0;
        if (name.includes('Panels')) {
          const panels = acc.panels + componentQuantitySum;
          return { ...acc, panels };
        } else if (name.includes('Invertors')) {
          const inverters = acc.inverters + componentQuantitySum;
          return { ...acc, inverters };
        } else if (name.includes('Battteries')) {
          const batteries = acc.batteries + componentQuantitySum;
          return { ...acc, batteries };
        }
        return acc;
      },
      { panels: 0, inverters: 0, batteries: 0 },
    );
  }, [component_maps]);

  const onRequestSurvey = () => {
    setModal({
      type: 'request-survey',
      content: {
        customTheme: customTheme,
        selectedSolarOption: selectedSolarOption,
        api_key,
        quoteDetails,
        decisions,
        token,
        ...rest,
      },
    });
  };

  const onModifyConfig = () => {
    setModal({
      type: 'modify-config',
      content: {
        bottomMobileOpen: true,
        customTheme: customTheme,
        selectedSolarOption: selectedSolarOption,
        api_key,
        token,
        quoteDetails,
        decisions,
        ...rest,
      },
    });
  };

  function RenderItem() {
    if (paymentOption === 'CASH') {
      return (
        <div className="tw-mt-6 md:tw-mt-8">
          <div className="flex  tw-flex-col ">
            <p className="font-28-32 inter-700-text natural-900-text">
              {isQuoteProduct
                ? getFormattedNumber(quoteDetails.total_amount, 'GBP')
                : getFormattedNumber(pricing?.total_amount, 'GBP')}
            </p>
            <p className="inter-500-text font-12 natural-500-text">*Fully inclusive all of products and service</p>
          </div>
        </div>
      );
    }

    const [number, decimal] = getFormattedNumber(
      calculatePeriodicPaymentAmount(isQuoteProduct ? quoteDetails.total_amount : pricing?.total_amount),
      'GBP',
    ).split('.');

    return (
      <div className="tw-mt-6 md:tw-mt-8">
        <div className="flex items-center gap-2">
          <div>
            <p className="font-28-32 inter-700-text natural-900-text">
              <span>{number}</span>
              <sup className="error-text">*</sup>
              <span className="inter-500-text font-16 ml--2 ">.{decimal}/mo</span>
            </p>
            <p className="inter-500-text font-12 natural-500-text">*Monthly price includes interest</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="tw-py-6 tw-px-4 md:tw-px-6">
      <div className="flex justify-between items-center">
        <p className="font-24-32 inter-700-text line-height-36 natural-900-text">Your system</p>
        <Button
          size="medium"
          label="Modify Package"
          className="nowrap"
          bgColor={customTheme.color.button}
          borderColor={customTheme.color.button}
          color={'white'}
          onClick={onModifyConfig}
        />
      </div>

      <div className="grid grid-6 tw-gap-2 md:tw-gap-4 mt-6">
        {[
          {
            id: 0,
            label: 'Peak Power',
            name: (selectedSolarOption?.config?.peak_power || 0) / 1000,
            subName: 'kWp',
            className: 'cols-3',
          },
          {
            id: 1,
            label: 'Annual Production',
            name: getFormattedNumberStyle(annual_production_kwh, 'decimal', 0, 0),
            subName: 'kWh',
            className: 'cols-3',
          },
          { id: 2, label: 'Panels', name: productBundles.panels, icon: SolarPanel, className: 'cols-2' },
          { id: 3, label: 'Inverters', name: productBundles.inverters, icon: Invertor, className: 'cols-2' },
          { id: 4, label: 'Batteries', name: productBundles.batteries, icon: Batteries, className: 'cols-2' },
        ].map(item => (
          <div key={item.id} className={classNames('relative bg-natural-50 rounded-3 p-4 border', item.className)}>
            <p className="inter-500-text font-12-16 line-height-150 natural-500-text">{item.label}</p>
            <div className="flex items-center justify-between mt-3">
              <div className="inter-700-text line-height-28  natural-700-text flex items-end gap-1 ">
                <p className="font-24">{item.name}</p>
                {item.subName && <p className="font-18">{item.subName}</p>}
              </div>
              {item.icon && (
                <div className="flex">
                  <item.icon className="natural-200-text" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <hr className="divider mt-6" />

      <div className="tw-mt-6 md:tw-mt-8">
        <RadioButton
          name="payment_option"
          value={paymentOption}
          accentColor={customTheme.color.button}
          onChange={option => setPaymentOption(option.value)}
          options={SOLAR_PAYMENT_OPTIONS}
          customTheme={{ ...customTheme, primary: customTheme.color.button }}
        />
      </div>

      <RenderItem />

      <hr className="divider mt-6" />

      <div className="mt-6">
        <Button
          width="100%"
          color={theme.white}
          label="Request Survey"
          bgColor={customTheme.color.button}
          borderColor={customTheme.color.button}
          size="large"
          disable={!decisions.length}
          afterIcon={<Arrow />}
          onClick={onRequestSurvey}
        />
      </div>
    </div>
  );
};

export default Finance;

const Specification = () => {
  return <></>;
};
