import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorDescription, getFormattedNumber, getFormattedNumberStyle } from '../../helpers/utils';
import SolarDesignContext from '../../pages/solar-design/SolarDesignContextApi';
import { setSelectedSolarOption, updateQuote } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import GoogleMaps from '../common/google-maps';

const SolarView = () => {
  const dispatch = useDispatch();
  const { token } = useContext(SolarDesignContext);

  const { selectedSolarOption, solarOptions, quoteDetails } = useSelector(state => state.quote);
  const { engagement, id: quote_id } = quoteDetails || {};
  const { property } = engagement || {};

  const { options, property: propertyCost, whole_roof } = solarOptions || {};
  const { center } = whole_roof || {};

  const { formatted_address } = property || {};
  const { config, estimate } = selectedSolarOption || {};
  const { system_size } = config || {};
  const { annual_production_kwh, annual_production_offset_pct, annual_savings } = estimate || {};

  const propertyAnnualCost = propertyCost?.annual_cost - annual_savings;

  const coordinates = useMemo(() => {
    return center;
  }, [center]);

  const optionPercetage = useMemo(() => {
    if (!options) return {};
    return options?.reduce((acc, item) => {
      const { annual_production_offset_pct } = item?.estimate || {};
      acc[annual_production_offset_pct] = item;
      return acc;
    }, {});
  }, [options]);

  const onSelectSolarOption = option => {
    onSaveQuote(option, selectedSolarOption);
  };

  const onSaveQuote = (option, prevOption) => {
    const { product: selectedProduct } = selectedSolarOption || {};
    const { product } = option || {};
    const { component_maps, id: productId } = product || {};

    if (selectedProduct?.id === productId) return;

    const panelBaseComponent =
      component_maps?.find(c => c.name === 'Solar Panels')?.components?.find(c => c.sell_type === 'BASE') || {};

    const invertorBaseComponent =
      component_maps?.find(c => c.name === 'Solar Invertors')?.components?.find(c => c.sell_type === 'BASE') || {};

    const batteryBaseComponent =
      component_maps?.find(c => c.name === 'Solar Battteries')?.components?.find(c => c.sell_type === 'BASE') || {};

    const panelComponentRequest = [{ id: panelBaseComponent?.id, qty: panelBaseComponent.quantity }];
    const invertorComponentRequest = [{ id: invertorBaseComponent?.id, qty: invertorBaseComponent.quantity }];
    const batteryComponentRequest = [{ id: batteryBaseComponent?.id, qty: batteryBaseComponent.quantity }];

    const componentsRequest = [...panelComponentRequest, ...invertorComponentRequest, ...batteryComponentRequest];

    const totalQuantity = componentsRequest.reduce((acc, item) => acc + item.qty, 0);

    const request = {
      products: [
        {
          id: productId,
          qty: totalQuantity,
          components: componentsRequest,
        },
      ],
    };

    dispatch(updateQuote({ quote_id: quote_id, request, token }))
      .then(() => {
        dispatch(setSelectedSolarOption(option));
      })
      .catch(error => {
        const errorText = getErrorDescription(error, 'Failed to update quote');
        dispatch(setSelectedSolarOption(prevOption));
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      });
  };

  return (
    <div className="tw-py-6 tw-px-4 md:tw-px-6">
      <div>
        <p className="font-24-32 inter-700-text line-height-36 natural-900-text tw-mb-4 md:tw-mb-6">
          Your Solar Design
        </p>
        <div>
          <p className="natural-900-text font-16-20 inter-500-text">
            <Trans
              i18nKey="BASE_SETUP_RECOMMENTATION"
              values={{
                system_size: getFormattedNumberStyle((system_size || 0) / 1000, 'decimal'),
                formatted_address,
                annual_cost: getFormattedNumber(propertyCost?.annual_cost),
              }}
              components={{ primary: <span className="custom-primary-text" /> }}
            />
          </p>
        </div>
      </div>
      <div className="tw-mt-4 md:tw-mt-8 grid grid-1-2 gap-6">
        <div className="rounded-3 overflow-hidden relative">
          <div className="google-maps-wrapper w-full">
            <GoogleMaps coordinates={coordinates} />
          </div>
        </div>

        <div>
          <div className="rounded-3 border bg-natural-50 p-4 tw-mb-4 md:tw-mb-6 flex-column">
            <p className="font-16 inter-600-text line-height-150 natural-900-text">Estimated electrical offset</p>
            <div className="relative bg-primary-100 flex radius-full mt-4 mb-6 overflow-auto flex-1 flex">
              {Object.keys(optionPercetage).map(progress => (
                <div
                  key={progress}
                  className="flex-1 cursor"
                  onClick={() => onSelectSolarOption(optionPercetage[progress])}>
                  <p
                    className={classNames(
                      'px-4 py-1_5 natural-900-text line-height-20 inter-500-text font-12',
                      parseFloat(annual_production_offset_pct) >= parseFloat(progress) && 'bg-primary text-white',
                      parseFloat(annual_production_offset_pct) === parseFloat(progress) &&
                        'text-white rounded-right font-16 inter-600-text',
                    )}>
                    {progress}%
                  </p>
                </div>
              ))}
            </div>
            <div className="flex md:tw-items-center justify-between">
              <div>
                <p className="font-16 natural-900-text inter-600-text line-height-150">New electrical bill</p>
              </div>
              <div className="tw-flex tw-flex-col md:tw-flex-row items-center col-gap-2 tw-justify-around">
                <p className="font-16 natural-400-text inter-400-text line-height-150 text-right strike-through-vertical">
                  {getFormattedNumber(propertyCost?.annual_cost)}
                </p>
                <p className="font-16 natural-900-text inter-600-text line-height-150 text-right">
                  {getFormattedNumber(propertyAnnualCost)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-column tw-gap-3 md:tw-gap-4">
            {[
              {
                id: 1,
                label: 'System Size',
                value: getFormattedNumberStyle((system_size || 0) / 1000, 'decimal'),
                placeholder: 'kwh',
              },
              {
                id: 2,
                label: 'Est. Annual Production',
                value: getFormattedNumberStyle(annual_production_kwh, 'decimal'),
                placeholder: 'kwh',
              },
            ].map(item => (
              <div key={item.id} className="inter-600-text natural-900-text flex justify-between">
                <p className="font-16 line-height-150">{item.label}</p>
                <p className="font-16 line-height-150">
                  {item.value} <span className="natural-400-text pl-1">{item.placeholder}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolarView;
