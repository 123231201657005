import React from 'react';
import styled from 'styled-components';
import AfterSteps from './AfterSteps';
import Finance from './Finance';
import Savings from './Savings';
import SolarBundles from './SolarBundles';
import SolarView from './SolarView';

const Panel = ({ panel }) => {
  return (
    <Wrapper panel={panel}>
      {panel.id === 'SOLAR_DESIGN' && <SolarView key={'solar-view'} />}
      {panel.id === 'FINANCE' && <Finance key={'finance-view'} />}
      {panel.id === 'EQUIPMENT_SELECTION' && <SolarBundles key={'solar-bundles-view'} />}
      {panel.id === 'DEPOSIT_INFO' && <AfterSteps key={'after-steps-view'} />}
      {panel.id === 'SAVINGS' && <Savings key={'saving-view'} />}
    </Wrapper>
  );
};

export default Panel;

const Wrapper = styled.div`
  background-color: ${({ panel }) => panel.panel_color};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.natural_200};
  box-shadow: 0px 8px 10px -6px rgba(16, 24, 40, 0.1), 0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  position: relative;

  .divider::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    left: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.natural_200};
  }
`;
