import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../components/common/loader';
import Panel from '../../components/solor-design/Panel';
import { useQuery } from '../../hooks/useQuery';
import {
  getDecisions,
  getFormDetails,
  getFormPanels,
  getPropertySolarDetails,
  getQuoteDetails,
  setFormDetails,
  setFormPanels,
  setQuoteDetails,
  setSelectedSolarOption,
  setSolarOptions,
} from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import { defaultCustom } from '../../styles/theme';
import ThankYou from '../thank-you';
import { SolarDesignContextProvider } from './SolarDesignContextApi';

const panelMatrix = [
  {
    id: 'FINANCE_VIEW',
    panels: [
      { id: 'FINANCE', name: 'FINANCE', panel_color: 'white' },
      { id: 'SAVINGS', name: 'SAVINGS', panel_color: 'white' },
    ],
  },
  {
    id: 'SOLAR_VIEW',
    panels: [
      { id: 'SOLAR_DESIGN', name: 'SOLAR_DESIGN', panel_color: 'white' },
      { id: 'EQUIPMENT_SELECTION', name: 'EQUIPMENT_SELECTION', panel_color: 'white' },
      { id: 'DEPOSIT_INFO', name: 'DEPOSIT_INFO', panel_color: 'white' },
    ],
  },
];

const Panels = () => {
  return (
    <PanelWrapper>
      {panelMatrix.map((row, index) => (
        <div key={row.id} className="panel-rows">
          {row.panels.map((panel, index) => (panel ? <Panel key={panel.id} panel={panel} /> : <div key={index} />))}
        </div>
      ))}
    </PanelWrapper>
  );
};

const SolarDesign = () => {
  const dispatch = useDispatch();
  const { form_id } = useParams();

  const {
    formDetails,
    quoteDetails,
    solarOptions,
    selectedSolarOption,
    decisions = [],
  } = useSelector(state => state.quote);

  const query = useQuery();
  const token = query.get('token');
  const quote_id = query.get('quote_id');

  const [loadingSolarOption, setLoadingSolarOption] = useState(true);
  const [decisionsFetching, setDecisionsFetching] = useState(false);

  const { api_key, attributes } = formDetails || {};
  const { engagement } = quoteDetails || {};
  const { property } = engagement || {};
  const { id: property_id } = property || {};

  const customTheme = useMemo(() => {
    const attribute = attributes?.[0] || defaultCustom;
    return attribute;
  }, [attributes]);

  const fetchFormDetails = form_id => {
    dispatch(getFormDetails({ form_id: form_id, store: true })).catch(error => {
      console.error(error);
      dispatch(addToast({ error: true, text: 'Failed to fetch form details', id: nanoid() }));
    });
  };

  const fetchQuoteDetails = quote_id => {
    dispatch(getQuoteDetails({ quote_id: quote_id, token, store: true })).catch(error => {
      console.error(error);
      setLoadingSolarOption(false);
      dispatch(addToast({ error: true, text: 'Failed to fetch quote details', id: nanoid() }));
    });
  };

  const fetchFormPanels = form_id => {
    dispatch(getFormPanels({ api_key, form_id, store: true })).catch(error => {
      dispatch(setFormPanels([]));
    });
  };

  const updateSolarOptions = (products, options) => {
    const productWithProductIds = products?.filter(p => p?.product?.id);
    const productComponents = productWithProductIds?.map(p => p?.components)?.flat();
    const productIds = productWithProductIds?.map(p => p?.product?.id);

    const newOptions = options?.map(o => {
      const { product } = o || {};
      const { component_maps, id } = product || {};
      const newComponentMaps = component_maps?.map(c => {
        const { components } = c || {};
        const selectedComponents =
          productIds?.includes(id) && components?.filter(c => productComponents?.find(p => p?.product?.id === c?.id));
        if (selectedComponents?.length) {
          const selectedComponentIds = selectedComponents.map(c => c.id);
          return {
            ...c,
            components: components?.map(c => ({ ...c, isSelected: selectedComponentIds.includes(c.id) })),
          };
        } else {
          return {
            ...c,
            components: components?.map(c => ({ ...c, isSelected: c.sell_type === 'BASE' })),
          };
        }
      });
      return {
        ...o,
        product: {
          ...product,
          component_maps: newComponentMaps,
        },
      };
    });
    return newOptions;
  };

  const fetchPropertySolarDetails = property_id => {
    setLoadingSolarOption(true);
    dispatch(getPropertySolarDetails({ token, api_key, property_id: property_id, store: true }))
      .then(data => {
        const { options } = data || {};
        const { products } = quoteDetails || {};
        const solarOptions = updateSolarOptions(products, options);
        const firstProductId = products?.find(p => p?.product?.id)?.product?.id;
        const firstOption =
          firstProductId && solarOptions
            ? solarOptions?.find(o => o?.product?.id === firstProductId)
            : solarOptions?.[0] || {};
        dispatch(setSolarOptions({ ...data, options: solarOptions }));
        dispatch(setSelectedSolarOption(firstOption));
      })
      .catch(error => {
        console.error(error);
        dispatch(addToast({ error: true, text: 'Failed to fetch property details', id: nanoid() }));
      })
      .finally(() => {
        setLoadingSolarOption(false);
      });
  };

  const fetchDecisions = () => {
    setDecisionsFetching(true);
    dispatch(getDecisions({ engagement_id: engagement.id, token })).finally(() => {
      setDecisionsFetching(false);
    });
  };

  useEffect(() => {
    ReactGA.event({
      category: 'SOLAR_QUOTE',
      label: 'QUOTE-NO',
      action: 'OPEN',
    });
  }, []);

  useEffect(() => {
    const { options } = solarOptions || {};
    if (quoteDetails && options?.length) {
      const { products } = quoteDetails || {};
      const newSolarOptions = updateSolarOptions(products, options);
      const newSelectedSolarOption =
        newSolarOptions?.find(o => o?.product?.id === selectedSolarOption?.product?.id) || {};
      dispatch(setSolarOptions({ ...solarOptions, options: newSolarOptions }));
      dispatch(setSelectedSolarOption(newSelectedSolarOption));
    }
  }, [quoteDetails]);

  useEffect(() => {
    fetchFormDetails(form_id);

    return () => {
      dispatch(setFormDetails({}));
      dispatch(setFormPanels([]));
    };
  }, [form_id]);

  useEffect(() => {
    if (token && quote_id) {
      fetchQuoteDetails(quote_id);
    }

    return () => {
      dispatch(setQuoteDetails({}));
    };
  }, [token, quote_id]);

  useEffect(() => {
    if (api_key) {
      fetchFormPanels(form_id);
    }

    return () => {
      dispatch(setFormPanels([]));
    };
  }, [api_key]);

  useEffect(() => {
    if (token && property_id) {
      fetchPropertySolarDetails(property_id);
    }

    return () => {
      dispatch(setSolarOptions([]));
      dispatch(setSelectedSolarOption({}));
    };
  }, [token, property_id]);

  useEffect(() => {
    if (engagement?.id) {
      fetchDecisions();
    }
  }, [engagement?.id]);

  return (
    <SolarDesignContextProvider
      value={{
        customTheme,
        api_key,
        token,
      }}>
      <SolarDesignWrapper customTheme={customTheme}>
        {loadingSolarOption || decisionsFetching ? <Loader /> : decisions?.length ? <Panels /> : <ThankYou />}
      </SolarDesignWrapper>
    </SolarDesignContextProvider>
  );
};

const SolarDesignWrapper = styled.div`
  height: 100dvh;
  background-color: ${({ customTheme }) => customTheme.background.color};
  overflow: auto;
  display: flex;
  justify-content: center;

  * {
    font-family: ${({ customTheme }) => customTheme.font.name || 'Inter'} !important;
  }

  .custom-primary-text {
    color: ${({ customTheme }) => customTheme.color.button};
  }

  .bg-primary {
    background-color: ${({ customTheme }) => customTheme.color.button};
  }

  .primary {
    background: ${({ customTheme }) => customTheme.color.button} !important;
  }
`;

const PanelWrapper = styled.div`
  display: grid;
  gap: 24px;
  overflow: auto;
  padding: 24px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    padding: 32px;
    grid-template-columns: 1fr 2fr;
  }
  .panel-rows {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .grid-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-1-2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .grid-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .cols-3 {
    grid-column: span 3;
  }

  .cols-2 {
    grid-column: span 2;
  }

  .rounded-right {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

  .google-maps-wrapper {
    height: 356px;
  }
`;

export default SolarDesign;
