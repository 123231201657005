import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ThankYouIllustration } from '../../assets/images/thank-you.svg';

const ThankYou = () => {
  const { t } = useTranslation();

  return (
    <div className="tw-h-screen tw-flex tw-items-center tw-justify-center px-6">
      <Wrapper className="card border border-natural-200 text-center tw-px-4 tw-py-6 md:tw-p-12">
        <div className="flex-column tw-items-center">
          <ThankYouIllustration className="tw-w-full" />
          <h1 className="tw-mt-8 md:tw-mt-14 tw-mb-4 inter-700-text font-28 line-height-36 natural-900-text">
            Thank you for requesting a survey!
          </h1>
          <p
            className="tw-mx-auto natural-500-text font-20 tw-leading-normal tw-px-2 md:tw-px-0"
            style={{ maxWidth: 600 }}>
            {t('SURVEY_BOOKING_CONFIRMED')}
          </p>
        </div>
      </Wrapper>
    </div>
  );
};

export default ThankYou;

const Wrapper = styled.div`
  max-width: 816px;
  width: 100%;
  letter-spacing: -0.02em;
`;
