import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import Checkbox from '../checkbox';

const RadioButton = ({ options, accentColor, value, onChange, customTheme = {} }) => {
  return (
    <RadioButtonWrapper className="flex tw-gap-4 md:tw-gap-6 flex-wrap" accentColor={accentColor}>
      {options.map((option, index) => (
        <div
          className={classNames(
            'radio-button py-2_5 px-4 border rounded-3 w-full cursor',
            value === option.value && 'active',
          )}
          key={index}
          onClick={() => onChange(option)}>
          <Checkbox
            customTheme={customTheme}
            is_rounded
            checked={value === option.value}
            onChange={() => {}}
            name={option.label}
          />
        </div>
      ))}
    </RadioButtonWrapper>
  );
};

const RadioButtonWrapper = styled.div`
  .radio-button {
    cursor: pointer;
    position: relative;
  }
  .active {
    background-color: ${({ theme }) => theme.primary_50};
    border-color: ${({ accentColor }) => accentColor};
  }
`;

export default RadioButton;
