import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OrganisationContext } from '../../context/organisationContext';
import { getAddressDetails, getAddressList } from '../../store/features/addressSlice';
import SearchableDropdown from '../common/searchable-dropdown';

const formatted_address_format = ['line1', 'line2', 'line3', 'city', 'state', 'postcode'];

const Address = ({ address, setAddress, loadingAddress, setLoadingAddress }) => {
  const dispatch = useDispatch();

  const { api_key } = useContext(OrganisationContext);

  const [search, setSearch] = useState('');

  const onAddressUpdate = (address, address_external_id) => {
    const formatted_address = formatted_address_format
      .map(a => address[a])
      .filter(value => Boolean(value))
      .join(', ');
    setAddress({
      address_external_id,
      is_billing: false,
      is_postal: false,
      is_primary: false,
      is_residential: false,
      ...address,
      formatted_address,
    });
  };

  const onAddressSelect = address => {
    setLoadingAddress(true);
    dispatch(getAddressDetails({ id: address.id, api_key }))
      .then(data => {
        onAddressUpdate(data, address.id);
      })
      .catch(e => {
        return '';
      })
      .finally(() => setLoadingAddress(false));
  };

  return (
    <SearchableDropdown
      loadOptionsOnMenuOpen={false}
      name="Address"
      placeholder={'Address'}
      isDisabled={!api_key}
      inputValue={search}
      onInputChange={setSearch}
      value={address ? { address: address } : null}
      onChange={onAddressSelect}
      isSearchable={true}
      getOptionLabel={data => data.address}
      getOptionValue={data => data.id}
      defaultAdditional={{
        page: 0,
        fetchFunction: getAddressList,
        payload: {
          api_key: api_key,
        },
        pageable: false,
      }}
    />
  );
};

export default Address;
