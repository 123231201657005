import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import classNames from 'classnames';
import React, { Fragment, memo, useCallback, useState } from 'react';
import SolarPanels from './solar-panels';

const libraries = ['places', 'geometry'];

const defaultOptions = { streetViewControl: true, mapTypeControl: true };
const solarOptions = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  mapTypeId: 'satellite',
  tilt: 0,
  rotateControl: false,
  zoomControl: false,
  maxZoom: 20,
  minZoom: 20,
  draggable: false,
};

const defaultLatLng = {
  lat: 50.935174,
  lng: -1.411288,
};

const GoogleMaps = ({ coordinates, wrapperClassName, isSolar = true }) => {
  const options = isSolar ? solarOptions : defaultOptions;
  const zoom = isSolar ? 20 : 19;
  const center = coordinates
    ? {
        lat: parseFloat(coordinates.lat),
        lng: parseFloat(coordinates.lon),
      }
    : defaultLatLng;

  const { isLoaded } = useJsApiLoader({
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(map => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, []);

  return (
    <div className={classNames('h-full w-full', wrapperClassName)}>
      {isLoaded ? (
        <Fragment>
          <GoogleMap
            center={center}
            id="map-google"
            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '6px' }}
            options={options}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {isSolar && <SolarPanels coord={center} map={map} />}
          </GoogleMap>
        </Fragment>
      ) : (
        <div />
      )}
    </div>
  );
};

export default memo(GoogleMaps);
