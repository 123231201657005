import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getFormattedNumber } from '../../helpers/utils';

const Savings = () => {
  const { solarOptions, selectedSolarOption } = useSelector(state => state.quote);

  const { property } = solarOptions || {};

  const { estimate } = selectedSolarOption || {};
  const { annual_savings } = estimate || {};

  const monthlySavings = annual_savings / 12;
  const profitOver25Years = annual_savings * 25;

  const propertyAnnualCost = property?.annual_cost - annual_savings;

  return (
    <SavingWrapper>
      <div className="tw-py-6 tw-px-4 md:tw-px-6 flex-column row-gap-4">
        <p className="font-24-28 inter-700-text">Your Savings</p>
        <div className="flex tw-gap-2 md:tw-gap-6">
          <div className="w-50 flex-column">
            <div className="bg-natural-50 rounded-3 p-4 border h-full flex-column justify-between">
              <p className="inter-500-text font-12-16 natural-500-text">Monthly bill savings</p>
              <p className="inter-700-text natural-700-text flex items-end gap-1 font-24">
                {getFormattedNumber(monthlySavings, undefined, 0, 0)}
              </p>
            </div>
            <div className="tw-mt-2 md:tw-mt-4 relative bg-natural-50 rounded-3 p-4 border h-full">
              <p className="inter-500-text font-12-16 natural-500-text">Profit over 25 years</p>
              <p className="inter-700-text natural-700-text flex items-end gap-1 mt-3 font-24">
                {getFormattedNumber(profitOver25Years, undefined, 0, 0)}
              </p>
            </div>
          </div>
          <div className="w-50 bg-natural-50 rounded-3 p-4 border flex-column">
            <p className="inter-500-text font-12-16 natural-500-text mb-2">Annual Electricity Bill</p>
            <div className="flex flex-1 items-end gap-6 bill-columns">
              <div className="flex-column flex-1 items-center bill-column">
                <p className="font-12 inter-700-text natural-700-text text-center nowrap">
                  {getFormattedNumber(property?.annual_cost)}
                </p>
                <div className="bg-primary rounded-2 mt-1 mb-2 w-full" style={{ height: '110px' }} />
                <p className="font-12 inter-500-text natural-700-text column-title tw-text-center">Without solar</p>
              </div>
              <div className="flex-column flex-1 items-center bill-column">
                <p className="font-12 inter-700-text natural-700-text text-center nowrap">
                  {getFormattedNumber(propertyAnnualCost)}
                </p>
                <div
                  className="bg-primary-500 rounded-2 mt-1 mb-2 w-full"
                  style={{ height: propertyAnnualCost ? '45px' : '5px' }}
                />
                <p className="font-12 inter-500-text natural-700-text column-title tw-text-center">With solar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SavingWrapper>
  );
};

const SavingWrapper = styled.div`
  .bill-columns {
    gap: 16px;
    @media (min-width: 768px) {
      gap: 24px;
    }
    .bill-column {
      max-width: 100%;
    }
    .column-title {
      text-align: center;

      @media (min-width: 1410px) {
        white-space: nowrap;
      }
    }
  }
`;

export default Savings;
