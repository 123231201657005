import styled from 'styled-components';

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto Bold;
  font-style: normal;
  width: ${props => props.width};
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  * {
    cursor: pointer;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
  }

  &.primary {
    background: ${({ theme }) => theme.primaryBlue};
    border: 1px solid ${({ theme }) => theme.primaryBlue};
    width: 100%;

    label {
      color: ${({ theme }) => theme.white};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.secondary {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.primary};
    width: 100%;

    label {
      color: ${({ theme }) => theme.primary};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.negative {
    background: ${({ theme }) => theme.error_500};
    border: 1px solid ${({ theme }) => theme.error_500};

    label {
      color: #ffffff;
    }
  }
  &.negative_white {
    background: ${({ theme }) => theme.error_50};
    border: 1px solid ${({ theme }) => theme.error_50};

    label {
      color: ${({ theme }) => theme.error_500};
    }
  }

  &.primary-white {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.primaryBlue};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-blue {
    background: ${({ theme }) => theme.primary_50};
    border: 1px solid ${({ theme }) => theme.primary_50};

    label {
      color: ${({ theme }) => theme.primaryBlue};
    }
  }

  &.primary-grey {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.natural_200};

    label {
      color: ${({ theme }) => theme.natural_700};
    }
  }

  &.success-white {
    background: ${({ theme }) => theme.success_50};
    border: 1px solid ${({ theme }) => theme.success_50};

    label {
      color: ${({ theme }) => theme.success_500};
    }
  }

  &.additional {
    background: ${({ theme }) => theme.additional};
    border: 1px solid ${({ theme }) => theme.additional};

    label {
      color: ${({ theme }) => theme.primary};
    }
  }

  &.custom-button-primary {
    background: ${({ theme }) => theme.button_color};
    border: 1px solid ${({ theme }) => theme.button_color};
    width: 100%;

    label {
      color: ${({ theme }) => theme.white};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.custom-button-secondary {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.button_color};
    width: 100%;

    label {
      color: ${({ theme }) => theme.button_color};
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed !important;

      * {
        cursor: not-allowed !important;
      }
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

export default ButtonWrapper;
