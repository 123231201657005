import React, { useContext, useState } from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ReactComponent as RightIcon } from '../../assets/images/arrow.svg';
import { ReactComponent as HousePanel } from '../../assets/images/house-panel.svg';
import Address from '../../components/address';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { OrganisationContext } from '../../context/organisationContext';
import { createNumberMask } from '../../helpers/createNumberMask';
import { getJobTypes } from '../../store/features/quoteSlice';

const typeOptions = [
  {
    value: 'SOLAR',
    label: 'Solar',
  },
  {
    value: 'BATTERY',
    label: 'Battery',
  },
  {
    value: 'SOLAR_BATTERY',
    label: 'Solar + Battery',
  },
  {
    value: 'EV_CHARGER',
    label: 'EV Charger',
  },
];

const QuoteDetails = ({ quoteDetails, setQuoteDetails, onGetQuote }) => {
  const { address, bill, type } = quoteDetails || {};
  const { api_key, getConfigIdByType } = useContext(OrganisationContext);

  const project = getConfigIdByType('PROJECT');

  const [loadingAddress, setLoadingAddress] = useState(false);

  const onUpdateQuoteDetails = (key, value) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
  };

  return (
    <QuoteDetailsWrapper className="w-full h-fit-content items-center col-gap-4">
      <IconContainer
        Icon={HousePanel}
        iconHeight={104}
        iconWidth={104}
        backgroundColor="transparent"
        iconColor="primary_500"
        iconContainerClassname="h-fit-content pxy-0 radius-1"
      />
      <div className="grid-cols">
        <Address
          address={address?.formatted_address}
          setAddress={value => onUpdateQuoteDetails('address', value)}
          loadingAddress={loadingAddress}
          setLoadingAddress={setLoadingAddress}
        />
        <div className="flex-column w-full">
          <div className="mb-1">
            <span className="inter-500-text natural-900-text">Monthly Electric Bill</span>
          </div>
          <MaskedInput
            mask={createNumberMask({
              prefix: '£',
              allowDecimal: true,
              includeThousandsSeparator: false,
            })}
            disabled={!api_key || loadingAddress}
            value={bill}
            className="input w-full"
            placeholder="£00.00"
            guide={false}
            onChange={({ target: { value } }) => onUpdateQuoteDetails('bill', value)}
          />
        </div>
        <SearchableDropdown
          defaultAdditional={{
            pageable: false,
            fetchFunction: getJobTypes,
            page: 0,
            payload: { api_key },
            params: {
              project_id: project?.id ? project.id : null,
            },
          }}
          value={type}
          isDisabled={!api_key || loadingAddress}
          onChange={option => onUpdateQuoteDetails('type', option)}
          placeholder="Type"
          name="Type"
        />
        <div className="flex items-end">
          <Button
            size="customsize"
            label="Get quote"
            width="150px"
            className="flex primary specified-width"
            onClick={onGetQuote}
            loading={loadingAddress}
            afterIcon={<RightIcon className="white-text" />}
            disabled={!api_key || !address || !bill || !type}
          />
        </div>
      </div>
    </QuoteDetailsWrapper>
  );
};

const QuoteDetailsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 104px 1fr;
  .grid-cols {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 150px;
    column-gap: 16px;
  }
`;

export default QuoteDetails;
