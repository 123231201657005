import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import { ModalContext } from '../../context/ModalContext';
import { getFormattedNumber } from '../../helpers/utils';
import SolarDesignContext from '../../pages/solar-design/SolarDesignContextApi';
import Button from '../common/button/button';

const SolarBundles = () => {
  const theme = useTheme();
  const { selectedSolarOption, quoteDetails, ...rest } = useSelector(state => state.quote);

  const { setModal } = useContext(ModalContext);
  const { customTheme, api_key, token } = useContext(SolarDesignContext);

  const { product } = selectedSolarOption || {};
  const { name, component_maps, id: productId, pricing } = product || {};

  const { products } = quoteDetails || {};
  const quoteProductIds = products?.map(p => p?.product?.id);
  const isQuoteProduct = quoteProductIds?.includes(productId);

  const componentMapsData = useMemo(() => {
    return component_maps?.map(curr => {
      const { components } = curr || {};
      const selectedComponents = components?.filter(c => c.isSelected);
      const baseComponent = components?.find(c => c.sell_type === 'BASE');
      return {
        ...curr,
        components: selectedComponents?.length ? [...selectedComponents] : baseComponent ? [baseComponent] : [],
      };
    });
  }, [component_maps]);

  const onModifyConfig = () => {
    setModal({
      type: 'modify-config',
      content: {
        bottomMobileOpen: true,
        customTheme: customTheme,
        selectedSolarOption: selectedSolarOption,
        api_key,
        quoteDetails,
        token,
        ...rest,
      },
    });
  };

  const handleSelectionInfoClick = component => {
    setModal({
      type: 'selection-info',
      content: {
        customTheme: customTheme,
        selectedSolarOption: selectedSolarOption,
        selectedComponent: component,
      },
    });
  };

  return (
    <div className="tw-py-6 tw-px-4 md:tw-px-6">
      <div className="col-gap-3 tw-flex tw-flex-col md:tw-flex-row md:tw-items-center tw-items-start justify-between mb-5 flex-1 tw-gap-4 md:tw-gap-0">
        <p className="natural-700-text font-20 inter-700-text">{name}</p>
        <Button
          size="medium"
          color={theme.primary_50}
          borderColor={'transparent'}
          className="px-4 nowrap"
          bgColor={customTheme.color.button}
          label="Modify Package"
          onClick={onModifyConfig}
        />
      </div>
      <div className="flex-column row-gap-6">
        {componentMapsData
          ?.filter(c => c.map_type !== 'ADD_ON' || (c.map_type === 'ADD_ON' && c.components?.length > 0))
          .map(main_component => (
            <div key={main_component.id} className="flex-column row-gap-1">
              <label className="inter-700-text">{main_component.name}</label>
              <div className="flex-column row-gap-4">
                {main_component.components.map(component => (
                  <div key={component?.id} className="border-natural-200 px-4 py-3 radius-1_5 bg-natural-50">
                    <div className="flex gap-4 items-start">
                      <div className="pxy-1 border-natural-200 radius-1_5 shadow-lg flex items-center bg-white">
                        <img
                          className="tw-object-cover"
                          style={{ height: '71px' }}
                          src={component?.image?.url}
                          width={54}
                          height={80}
                        />
                      </div>
                      <div className="flex items-start justify-between flex-1 tw-flex-col md:tw-flex-row">
                        <div className="flex-1">
                          <p className="mb-1 font-14 color-neutral-900 inter-600-text">{component?.name}</p>
                          <p
                            className="font-12-14 color-gray-secondary inter-400-text tw-mb-4 md:tw-mb-0 "
                            style={{ maxWidth: '550px' }}>
                            {component?.description}
                          </p>
                        </div>
                        <div className="tw-flex md:tw-flex-col max-md:tw-w-full tw-justify-between tw-items-end md:tw-self-stretch">
                          <div className="px-3 py-0_5 flex gap-1 bg-lightgray-1 color-light-black-1 radius-full">
                            <span className="inter-500-text">Qty: </span>
                            <span className="inter-500-text">{component?.quantity}</span>
                          </div>
                          <button onClick={() => handleSelectionInfoClick(component)}>
                            <Info />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>

      <hr className="divider mt-8" />

      <div className="natural-700-text font-28 pt-6 inter-700-text flex justify-between">
        <p className="">Total price</p>
        <p>
          {isQuoteProduct
            ? getFormattedNumber(quoteDetails.total_amount, 'GBP')
            : getFormattedNumber(pricing?.total_amount || 0, 'GBP')}
        </p>
      </div>
    </div>
  );
};

export default SolarBundles;
