import React from 'react';

const AfterSteps = () => {
  const items = [
    {
      title:
        'Press the request a survey button and provide your preferred contact number and contact time ( if you haven’t already )',
    },
    {
      title:
        'We will give you a call to schedule in your site survey for a time that suits you. Review the electrical set up and make any adjustments to the estimate that are needed / wanted based on your feedback',
    },
    {
      title: 'We will install the system and you will start saving!',
    },
    {
      title:
        'We submit all the necessary paperwork and get you registered with your energy supplier for the Smart Export Gurantee',
    },
  ];
  return (
    <>
      <div className="tw-py-6 tw-px-4 md:tw-px-6">
        <p className="color-neutral-900 font-24-28 inter-700-text tw-mb-6 md:tw-mb-8">
          What Happens if I like the look of my quote?
        </p>
        <div>
          {items.map((singleItem, index) => {
            return (
              <div
                key={index}
                className="tw-mb-4 md:tw-mb-6 flex desktop-items-center tw-items-start md:tw-items-center gap-4">
                <div
                  style={{ height: '48px', width: '48px' }}
                  className="bg-natural-50 flex items-center justify-center shrink-0 rounded-3 border-natural-200">
                  <span className="inter-700-text color-light-black-1 font-16">{index + 1}</span>
                </div>
                <p className="inter-500-text color-light-black-1 font-16">{singleItem.title}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AfterSteps;
