import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as SolarPanelIcon } from '../../../assets/images/panel.svg';

const SolarPanels = ({ map }) => {
  const { t } = useTranslation();
  const solarPanelPolygonReferencesRef = useRef(new Map());
  const solarPanelPolygonsRef = useRef([]);

  const { solarOptions, selectedSolarOption } = useSelector(state => state.quote);
  const { roof_segments, property } = solarOptions || {};
  const { rgb_map_url } = property || {};

  const { config } = selectedSolarOption || {};
  const { panels: panelsToShow, segments } = config || {};

  const [showPanels, setShowPanels] = useState(false);

  useEffect(() => {
    if (roof_segments && roof_segments.length > 0 && map && showPanels && segments) {
      const solarPanelPolygons = [];
      const solarPanelPolygonReferences = solarPanelPolygonReferencesRef.current;
      let panelsCount = 0;

      segments?.forEach(segment => {
        const { segment_id, panels } = segment;
        const roofSegmentSummary = roof_segments.find((_, index) => index === segment_id);
        const roofPanels = roofSegmentSummary?.panels?.slice(0, panels);
        roofPanels.forEach(solarPanel => {
          const { center: panelCenter } = solarPanel;
          const solarPanelId = `${panelCenter.lat}-${panelCenter.lon}`;
          let height = 1.684 / 2;
          let width = 1.002 / 2;

          if (solarPanel.orientation === 'LANDSCAPE') {
            const previousHeight = height;

            height = width;
            width = previousHeight;
          }

          const angle = roofSegmentSummary.azimuth_degrees;

          if (!solarPanelPolygonReferences.has(solarPanelId)) {
            const center = {
              lat: panelCenter.lat,
              lng: panelCenter.lon,
            };

            const top = google.maps.geometry.spherical.computeOffset(center, height, angle + 0);
            const right = google.maps.geometry.spherical.computeOffset(center, width, angle + 90);
            const left = google.maps.geometry.spherical.computeOffset(center, width, angle + 270);

            const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
            const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
            const bottomLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 180);
            const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

            solarPanelPolygonReferences.set(
              solarPanelId,
              new google.maps.Polygon({
                map: map,

                fillColor: '#2B2478',
                fillOpacity: 0.8,

                strokeWeight: 1,
                strokeColor: '#AAAFCA',
                strokeOpacity: 1,

                geodesic: false,

                paths: [topRight, bottomRight, bottomLeft, topLeft],
              }),
            );
          }
          const polygon = solarPanelPolygonReferences.get(solarPanelId);
          polygon?.setMap(map);

          solarPanelPolygons.push(polygon);
        });
        solarPanelPolygonsRef.current = solarPanelPolygons;
        solarPanelPolygonReferencesRef.current = solarPanelPolygonReferences;
        panelsCount += roofSegmentSummary.panels.length;
      });
    }

    return () => {
      solarPanelPolygonsRef.current.forEach(polygon => polygon.setMap(null));
    };
  }, [panelsToShow, roof_segments, map, showPanels, segments]);

  return (
    <>
      <SolarPanelWrapper
        className="bg-white radius-2 pxy-2 cursor"
        as={'button'}
        onClick={e => setShowPanels(!showPanels)}>
        <SolarPanelIcon
          width={16}
          height={16}
          className={classNames(showPanels ? 'primary-500-text' : 'natural-500-text')}
        />
      </SolarPanelWrapper>
      {showPanels && (
        <div className="absolute p-4 right-0 bottom-0 left-0 bg-natural-900 opacity-8">
          <div>
            <p className="inter-400-text font-12 text-white line-height-150">{t('PANEL_PLACEMENT_INFO')}</p>
          </div>
        </div>
      )}
    </>
  );
};

const SolarPanelWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export default SolarPanels;
