import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomerDetails from '../../components/customer-details';
import QuoteDetails from '../../components/quote-details';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorDescription } from '../../helpers/utils';
import { createJob, getFormConfigs, getFormDetails, getFormPanels } from '../../store/features/quoteSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';

const Main = () => {
  const dispatch = useDispatch();
  const { form_id } = useParams();

  const [formDetails, setFormDetails] = useState({});
  const [formPanels, setFormPanels] = useState([]);
  const [formConfigs, setFormConfigs] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState({});
  const [quoteDetails, setQuoteDetails] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [currentStep, setCurrentStep] = useState('QUOTE_DETAILS');
  const [loading, setLoading] = useState(false);

  const { api_key } = formDetails || {};
  const { panel_color, transparency } = selectedPanel || { panel_color: '#FFFFFF' };

  const resetState = () => {
    setQuoteDetails({});
    setCustomerDetails({});
    setCurrentStep('QUOTE_DETAILS');
  };

  const fetchFormDetails = form_id => {
    dispatch(getFormDetails({ form_id: form_id }))
      .then(data => setFormDetails(data))
      .catch(error => {
        console.error(error);
        dispatch(addToast({ error: true, text: 'Failed to fetch form details', id: nanoid() }));
      });
  };

  const fetchFormPanels = form_id => {
    dispatch(getFormPanels({ api_key, form_id }))
      .then(data => {
        setFormPanels(data);
        setSelectedPanel(data[0]);
      })
      .catch(error => {
        setFormPanels([]);
      });
  };

  const fetchFormConfigs = form_id => {
    dispatch(getFormConfigs({ api_key, form_id: form_id }))
      .then(data => setFormConfigs(data))
      .catch(error => {
        console.error(error);
        setFormConfigs([]);
        dispatch(addToast({ error: true, text: 'Failed to fetch form configs', id: nanoid() }));
      });
  };

  useEffect(() => {
    fetchFormDetails(form_id);
  }, [form_id]);

  useEffect(() => {
    if (api_key) {
      fetchFormPanels(form_id);
      fetchFormConfigs(form_id);
    }
  }, [api_key]);

  const onGetQuote = () => {
    setCurrentStep('CUSTOMER_DETAILS');
    setSelectedPanel(formPanels[1] || {});
  };

  const getConfigIdByType = configType => {
    const configData = formConfigs.find(config => config.tag === configType);
    const { config } = configData || {};
    if (!config) {
      return null;
    }
    return { id: config.id };
  };

  const onDone = () => {
    const { address, bill, type } = quoteDetails || {};
    const { name, email, mobile, country_code } = customerDetails || {};
    const { address_external_id, integration_id, ...restAddress } = address || {};
    const annual_electric_cost = bill.replaceAll('£', '') * 12 || 0;
    const leadSource = getConfigIdByType('LEAD_SOURCE');

    const request = {
      job_type: {
        id: type?.id,
      },
      engagement: {
        parent: null,
        name: type?.label || '',
        project: getConfigIdByType('PROJECT'),
        campaign: getConfigIdByType('CAMPAIGN'),
        engagement_dates: null,
        engagement_value: null,
        quote: null,
        primary_contact: {
          title: '',
          forename: name?.split(' ')[0] || '',
          surname: name?.split(' ')[1] || '',
          language: null,
          timezone: null,
          date_of_birth: null,
          pronouns: null,
          gender: null,
          profession: null,
          company_name: null,
          contact_type: 'PERSON',
          parent: null,
          job_title: null,
          emails: [
            {
              value: email,
              is_primary: true,
              email_type: 'WORK',
            },
          ],
          phones:
            mobile?.length === 12
              ? [
                  {
                    contact_type: 'MOBILE',
                    contact_value: mobile?.replaceAll(' ', '')?.replaceAll('-', ''),
                    is_primary: true,
                    phone_type: 'WORK',
                    country_code: country_code || '+44',
                  },
                ]
              : null,
          addresses: [
            {
              ...restAddress,
            },
          ],
          call_time: null,
          property_contact_type: 'THIRD_PARTY',
          shared_percentage: 0,
        },
        property: {
          ...restAddress,
          address_reference: {
            integration_id: integration_id,
            external_reference: address_external_id,
          },
          details: {
            annual_electric_cost: parseFloat(annual_electric_cost).toFixed(4),
          },
          property_class: {
            class_type: getConfigIdByType('PROPERTY_TYPE')?.id || 'RESIDENTIAL',
          },
        },
        workflow: getConfigIdByType('WORKFLOW'),
        agent: null,
      },
      job_number: '',
      po_number: '',
      job_description: '',
      lead_gen: leadSource
        ? {
            organization: leadSource,
          }
        : null,
      partners: null,
    };
    setLoading(true);
    dispatch(createJob({ api_key, request }))
      .then(data => {
        dispatch(addToast({ error: false, text: 'Job created successfully', id: nanoid() }));
        resetState();
      })
      .catch(error => {
        const errorText = getErrorDescription(error, 'Failed to create job');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MainWrapper
      className="w-full main flex flex-1 pxy-4 items-center"
      transparency={transparency}
      style={{ backgroundColor: panel_color }}>
      <OrganisationContext.Provider value={{ api_key, getConfigIdByType }}>
        <div className="wrapper">
          {currentStep === 'QUOTE_DETAILS' && (
            <QuoteDetails quoteDetails={quoteDetails} setQuoteDetails={setQuoteDetails} onGetQuote={onGetQuote} />
          )}
          {currentStep === 'CUSTOMER_DETAILS' && (
            <CustomerDetails
              customerDetails={customerDetails}
              setCustomerDetails={setCustomerDetails}
              onDone={onDone}
              loading={loading}
            />
          )}
        </div>
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
