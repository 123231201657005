import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import { ReactComponent as UserPanelIcon } from '../../assets/images/user-panel.svg';
import Button from '../../components/common/button/button';
import IconContainer from '../../components/common/icon-container';
import InputElement from '../../components/common/input';
import PhoneInput from '../../components/common/phone-input';

const CustomerDetails = ({ customerDetails, setCustomerDetails, onDone, loading }) => {
  const { name, email, mobile, country_code } = customerDetails || {};

  const onUpdateCustomerDetails = (key, value) => {
    setCustomerDetails({ ...customerDetails, [key]: value });
  };

  return (
    <CustomerDetailsWrapper className="w-full h-fit-content items-center col-gap-4 customer-details">
      <IconContainer
        Icon={UserPanelIcon}
        iconHeight={104}
        iconWidth={104}
        backgroundColor="transparent"
        iconColor="primary_500"
        iconContainerClassname="h-fit-content radius-1"
      />
      <div className="grid-cols">
        <InputElement
          value={name}
          onChange={value => onUpdateCustomerDetails('name', value)}
          placeholder="Name"
          name="Full name"
        />
        <InputElement
          value={email}
          onChange={value => onUpdateCustomerDetails('email', value)}
          placeholder="Email"
          name="Email"
        />
        <div className="flex-column w-full">
          <div className="mb-1">
            <label className="inter-500-text natural-900-text">
              Mobile <span className="natural-400-text">{'(Optional)'}</span>
            </label>
          </div>
          <PhoneInput
            selectedCountry={country_code || '+44'}
            setSelectedCountry={value => onUpdateCustomerDetails('country_code', value)}
            phone={mobile}
            setPhone={value => onUpdateCustomerDetails('mobile', value)}
          />
        </div>
        <div className="flex items-end">
          <Button
            size="customsize"
            label="Done"
            width="150px"
            loading={loading}
            className="flex primary specified-width mt-3"
            disabled={!name || !email}
            onClick={onDone}
            afterIcon={<CheckIcon className="white-text" />}
          />
        </div>
      </div>
    </CustomerDetailsWrapper>
  );
};

const CustomerDetailsWrapper = styled('div')`
  display: grid;
  grid-template-columns: 104px 1fr;
  .grid-cols {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 150px;
    column-gap: 16px;
  }
`;

export default CustomerDetails;
